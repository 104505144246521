// @ts-strict-ignore
import ReactDOM from 'react-dom'
import React from 'react'
import queryString from 'query-string'
import { camelizeKeys } from 'humps'

function parse(search: string) {
  return queryString.parse(search, { parseBooleans: true, parseNumbers: true })
}

;(async () => {
  const propsElement = document.querySelector(`[data-react-class]`)
  const componentName = propsElement.getAttribute('data-react-class')
  const Component = (await import(`./${componentName}/index.tsx`))[componentName]
  const props = camelizeKeys({
    ...parse(window.location.search),
    ...JSON.parse(propsElement.getAttribute('data-react-props')),
  })
  ReactDOM.render(
    <React.StrictMode>
      <Component {...props} />
    </React.StrictMode>,
    document.getElementById('root'),
  )
})()
